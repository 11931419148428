<template>
  <section>
    <b-row>
      <b-col md="6">
        <b-card class="text-center bg-gradient-primary">
          <b-avatar class="mb-2" />

          <p>Benvenuto <b>nome cognome</b></p>
          <span
            >In questo pannello potrai vedere le comunicazioni giornaliere</span
          >
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card>
          <h2 class="text-primary-light">Bacheca Informativa</h2>

          <p>
            Gentile Cliente, <br />
            Lorem Ipsum...
          </p>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCol, BRow, BAvatar, BCard } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    BAvatar,
    BCard,
  },
};
</script>
